$color-standard: #ec661d;

$color-menu: rgb(225, 97, 28);
$color-menu-active: rgb(229, 115, 55);

.page-home {
    .swiper {
        width: 100%;
        max-width: 19.2rem;
        margin: auto;
        position: relative;
        .swiper-slide {
            display: flex;
            justify-content: center;
        }
        .banner {
            width: 19.2rem;
            height: 4.2rem;
            flex-shrink: 0;
            // height: 100%;
            // display: block;
            // position: absolute;
            // left: 50%;
            // margin-left: -9.6rem;
        }
        .swiper-pagination-custom {
            width: 0.51rem;
            display: inline-block;
            text-align: center;
            left: 50%;
            bottom: 0;
            transform: translate(4.4rem);
            background: rgba(0, 0, 0, 0.2);
            font-size: 16px;
            color: rgba(255, 255, 255, 0.85);
            &::first-letter {
                color: #fff;
            }
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        left: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        width: 0.36rem;
        height: 0.36rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
            display: none;
        }
        .arrow {
            width: 0.36rem;
            height: 0.36rem;
            display: block;
        }
        &:hover {
            background: #ec661d;
        }
    }
    .swiper-button-prev {
        transform: translateX(-5.06rem);
    }
    .swiper-button-next {
        transform: translateX(4.7rem);
        .arrow {
            transform: rotate(180deg);
        }
    }
    .swiper-slide {
        position: relative;
        overflow: hidden;
        font-size: 0;
    }

    .homeDownload {
        max-width: 19.2rem;
        height: 7.2rem;
        margin: auto;
        position: relative;
        overflow: hidden;
        .img-box {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
        }
        .banner {
            width: 19.2rem;
            height: 7.2rem;
            object-fit: cover;
        }
        .app-icon-box {
            position: relative;
            z-index: 1;
            margin-top: 0.6rem;
            .top-title {
                display: flex;
                align-items: center;
                img {
                    margin-left: 0.04rem;
                    width: 60px;
                    height: 60px;
                    margin-right: 0.16rem;
                }
                span {
                    font-size: 0.36rem;
                    color: #fff;
                }
            }
            p {
                margin-top: 0.21rem;
                font-size: 0.6rem;
                font-weight: bold;
                color: #fff;
                line-height: 0.8rem;
            }
        }
        .download-box {
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
            max-width: 10.12rem;
            position: absolute;
            z-index: 1;
            bottom: 0.9rem;
            left: 50%;
            transform: translateX(-50%);
        }
        .download {
            position: relative;
            z-index: 1;
            display: flex;
            .qrcode {
                width: 1.36rem;
                height: 1.36rem;
                background-color: #fff;
                margin-right: 0.16rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .buttons {
                > a {
                    display: flex;
                    align-items: center;
                    background: rgba(255, 255, 255, 0);
                    border: 1px solid rgba(255, 255, 255, 0.85);
                    font-size: 0.18rem;
                    color: #fff;
                    font-family: SourceHanSansCN-Normal;
                    height: 0.6rem;
                    padding-right: 0.16rem;
                    &:first-child {
                        margin-bottom: 0.16rem;
                    }
                    .divider {
                        background: rgba(255, 255, 255, 0.45);
                        width: 1px;
                        height: 0.12rem;
                        display: block;
                        margin: 0 0.16rem;
                    }
                    > img {
                        margin-left: 0.16rem;
                        display: block;
                        width: 0.24rem;
                        height: 0.24rem;
                    }
                }
            }
        }
    }

    .news {
        display: flex;
        padding-top: 40px;
        padding-bottom: 40px;
        .firstNews {
            width: 6.28rem;
            margin-right: 0.12rem;
            .img {
                display: block;
                width: 100%;
                height: 3.54rem;
                object-fit: cover;
            }
            .info {
                width: 100%;
                height: 1.52rem;
                display: block;
                background: url("./images/news_bg@2x.png") no-repeat;
                background-size: cover;
                padding: 0.24rem;
                box-sizing: border-box;
            }
            .title {
                width: 100%;
                height: 0.56rem;
                margin-bottom: 0.24rem;
                font-size: 0.2rem;
                color: #ffffff;
                font-weight: bold;
                line-height: 0.28rem;
            }
            .ft {
                display: flex;
                .date {
                    flex: 1;
                    color: #ffffff;
                }
                .arrow {
                    width: 24px;
                    height: 24px;
                    transform: rotate(180deg);
                }
            }
        }
        .secondNews {
            width: 3.72rem;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            .img {
                display: block;
                width: 100%;
                height: 2.1rem;
                object-fit: cover;
            }
            .info {
                flex: 1;
                height: 2.94rem;
                box-sizing: border-box;
                padding: 0.24rem;
                padding-bottom: 0;
                display: flex;
                flex-direction: column;
            }
            .title {
                height: 0.56rem;
                flex-shrink: 0;
                line-height: 0.28rem;
                font-size: 0.2rem;
                color: #333;
                font-weight: bold;
                margin-bottom: 0.23rem;
            }
            .desc-box {
                flex: 1;
            }
            .desc {
                height: 0.96rem;
                font-size: 0.16rem;
                color: #666666;
                line-height: 0.24rem;
            }
            .ft {
                border-top: 1px solid rgba(0, 0, 0, 0.08);
                font-size: 0.16rem;
                color: #666666;
                height: 0.72rem;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .date {
                    flex: 1;
                }
                .arrow {
                    display: block;
                    width: 0.24rem;
                    height: 0.24rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .page-home {
        .news {
            display: block;
            .firstNews {
                margin: 0 auto 16px auto;
                width: 100%;
                .img {
                    height: auto;
                }
                .title {
                    font-size: 14px;
                }
                .date {
                    font-size: 12px;
                }
            }
            .secondNews {
                width: 100%;
                .title {
                    font-size: 14px;
                }
                .desc,
                .date {
                    font-size: 12px;
                }
            }
        }
    }
}

.page-biz-intro {
    background-color: #ffff;
    .bizIntroMain {
        padding-bottom: 0;
    }
    .bizIntroCon {
        // padding-bottom: 40px;
        img {
            display: block;
            width: 100%;
        }
    }
    .bizIntroTitle {
        margin-top: 40px;
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        font-weight: bold;
        margin-bottom: 24px;
        &::after {
            display: block;
            content: "";
            width: 44px;
            height: 6px;
            margin-top: 24px;
            background: #ec661d;
        }
    }
    .anchor {
        display: block;
        height: 80px;
        width: 1px;
        + .bizIntroTitle {
            margin-top: 0px;
        }
    }
    .bizIntroSubTitle {
        font-size: 20px;
        color: #666666;
        line-height: 28px;
        font-weight: normal;
        padding-top: 16px;
    }
}

@media screen and (max-width: 428px) {
    .page-biz-intro {
        background: #fafafa;
    }
}

.biz-intro-mobile {
    .app-mobile-topbanner {
        width: 100%;
        position: relative;
        font-size: 0;
        .mobile-banner {
            width: 100%;
        }
        .slogan-mobile {
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0.4rem;
            color: #fff;
            h1 {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 4px;
            }
            h2 {
                font-size: 14px;
                line-height: 22px;
                font-weight: normal;
            }
        }
    }
    .biz-content-box {
        padding: 24px 12px 12px 12px;
        .bizIntroTitle {
            margin: 0;
            font-size: 18px;
            color: #333333;
            line-height: 26px;
            &::after {
                width: 24px;
                height: 4px;
                margin-top: 12px;
            }
        }
        .bizIntroSubTitle {
            margin-top: 4px;
            font-size: 12px;
            line-height: 20px;
            padding-top: 0;
            color: #666666;
        }
        .go-biz-type {
            position: relative;
            margin-bottom: 16px;
            a {
                display: flex;
                align-items: center;
                position: absolute;
                right: 0;
                bottom: 0;
                font-size: 14px;
                color: #666666;
                line-height: 22px;
                .arrow {
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                }
            }
        }
        .biz-card {
            background: #ffffff;
            border-radius: 2px;
            margin-bottom: 8px;
            .biz-card-title {
                padding: 16px 12px;
                font-size: 18px;
                color: #333333;
                line-height: 26px;
                font-weight: bold;
            }
            .card-items {
                padding: 0 12px 12px 12px;
                .card-item-box {
                    display: flex;
                    align-items: center;
                    padding: 16px 12px;
                    background: #fafafa;
                    border-radius: 2px;
                    font-size: 14px;
                    color: #333333;
                    line-height: 22px;
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                    .arrow {
                        width: 16px;
                        height: 16px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .biz-card.last-card {
            margin-bottom: 24px;
        }
        .drug-delivery {
            background: #ffffff;
            border-radius: 2px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            color: #333333;
            font-weight: bold;
            padding: 0 24px;
            margin-top: 16px;
            span {
                margin-right: 12px;
            }
            .arrow {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
            }
        }
        .business-model {
            .img-list {
                margin-top: 16px;
                img {
                    width: 100%;
                }
            }
        }
    }
}

#swiper-mobile {
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    background: #f7f7f7;
    .banner {
        width: 100%;
    }
    .swiper-pagination-horizontal {
        bottom: 6px;
        height: 6px;
        line-height: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        .swiper-pagination-bullet {
            display: inline-block;
            width: 3px;
            height: 3px;
            // background: rgba(0, 0, 0, 0.16);
            margin: 0;
            margin-right: 3px;
        }
        .swiper-pagination-bullet-active {
            width: 8px;
            background: #ff6936;
            border-radius: 2px;
        }
    }
    .a-box {
        display: block;
        width: 100%;
        font-size: 0;
        img {
            width: 100%;
        }
    }
    .text-box {
        color: #ffffff;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .title {
        font-size: 0.18rem;
        white-space: nowrap;
        margin-bottom: 0.04rem;
        font-weight: bold;
    }
    .subTitle {
        font-size: 0.12rem;
        white-space: nowrap;
    }
    .bottom-ribbon {
        position: relative;
        z-index: 2;
        transform: translateY(-10px);
        height: 20px;
        width: 100%;
    }
}

.app-mobile-home {
    .news-list-box {
        padding: 0 12px 12px 12px;
        .news-list {
            .news-item {
                padding: 12px;
                margin-bottom: 8px;
                background: #fff;
                border-radius: 2px;
                display: flex;
                align-items: center;
                .left-img {
                    width: 120px;
                    height: 80px;
                    object-fit: cover;
                    margin-right: 8px;
                    flex-shrink: 0;
                }
                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 80px;
                    .right-title {
                        font-size: 14px;
                        color: #333333;
                        line-height: 22px;
                        margin-bottom: 16px;
                    }
                    .right-date {
                        font-size: 12px;
                        color: #666666;
                        line-height: 20px;
                    }
                }
            }
            .look-more {
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #666666;
                background: #ffffff;
                border-radius: 2px;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
